<template lang="pug">
TableV2(
  :actions="actions"
  :headers="headers"
  :isLoading="isLoading"
  :items="items.results?.data || []"
  :currentPage="items.current"
  :pageCount="items.count"
  :querySearch="getData"
  :total="items.results"
  :isNotMounted="!!!$route.params.type"
).px-4
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  name: 'TableDebtor',
  data () {
    return {
      headers: [],
      actions: [
        {
          id: 1,
          to: (item) => this.checkTypeDocument(item),
          color: 'blue',
          target: '_blank',
          name: 'mdi-information-outline',
          tooltip: 'tooltip.info',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: state => state.report.listDistributionCrewing,
      isLoading: state => state.report.isLoadingReport
    })
  },
  methods: {
    ...mapActions(['getListDistributionCrewing']),
    async getData (params) {
      this.headers = [
        { value: 'count', text: this.$t('itemsAmount'), align: 'center', total: { global: 'global_sum_count', page: 'page_count_sum', align: 'center' }, sortable: false },
        { value: 'distribution_sum', text: this.$t('distribution'), align: 'center', total: { global: 'global_form2_distribution', page: 'global_form2_distribution', align: 'center' }, sortable: false },
        { value: 'profit_sum', text: this.$t('profit'), align: 'center', total: { global: 'global_form2_profit', page: 'page_form2_profit', align: 'center' }, sortable: false },
        { value: 'event', text: this.$t('actions'), align: 'center', sortable: false }
      ]
      await this.getListDistributionCrewing({ params: { ...params, ...this.$route.params }, link: this.checkLink() })
    },
    checkTypeDocument (item) {
      switch (this.$route.params.typeDocument) {
        case 'sqc':
        case 'crewing_manager':
          if (!this.headers.find(column => column.value === 'group')) {
            this.headers.unshift({
              value: 'group',
              text: this.$t('group'),
              title: { global: this.$t('totalGlobal'), page: this.$t('totalOnPage') },
              sortable: false })
          }
          return ({
            name: 'distribution-usual-report-crewing-group-table',
            params: { crewing_group: item.group || 0 },
            query: { ...this.$route.query } })
        case 'dpd':
          if (!!this.$route.params.type && !this.headers.find(column => column.value === 'crewing')) {
            this.headers.unshift({
              value: 'crewing',
              text: this.$t('crewing'),
              title: { global: this.$t('totalGlobal'), page: this.$t('totalOnPage') },
              sortable: false })
          } else if (!this.$route.params.type && !this.headers.find(column => column.value === 'group')) {
            this.headers.unshift({
              value: 'group',
              text: this.$t('group'),
              title: { global: this.$t('totalGlobal'), page: this.$t('totalOnPage') },
              sortable: false })
          }
          return (this.$route.params.type
            ? { name: 'distribution-usual-report-manager-type-document',
              params: { crewing: item.crewing.id },
              query: { ...this.$route.query } }
            : { name: 'distribution-usual-report-document-type-table',
              params: { crewing_group: item.group || 0 },
              query: { ...this.$route.query } })
        case 'eti':
          if (!this.headers.find(column => column.value === 'eti')) {
            this.headers.unshift({
              value: 'eti',
              text: this.$t('eti'),
              title: { global: this.$t('totalGlobal'), page: this.$t('totalOnPage') },
              sortable: false })
          }
          return ({ name: 'distribution-usual-report-manager-institution',
            params: { institution: item.eti.id },
            query: { ...this.$route.query } })
        case 'medical':
          if (!this.headers.find(column => column.value === 'medical_institution')) {
            this.headers.unshift({
              value: 'medical_institution',
              text: this.$t('medicalInstitution'),
              title: { global: this.$t('totalGlobal'), page: this.$t('totalOnPage') },
              sortable: false })
          }
          return ({ name: 'distribution-usual-report-manager-medical', params: { medical: item.medical_institution.id } })
        case 'portal':
        case 'sc':
          if (!this.headers.find(column => column.value === 'branch_office')) {
            this.headers.unshift({
              value: 'branch_office',
              text: this.$t('affiliate'),
              title: { global: this.$t('totalGlobal'), page: this.$t('totalOnPage') },
              sortable: false })
          }
          return ({ name: 'distribution-usual-report-manager-branch-office',
            params: { branch_office: item.branch_office.id },
            query: { ...this.$route.query } })
        case 'adv_training':
          if (!this.headers.find(column => column.value === 'educational_institution')) {
            this.headers.unshift({
              value: 'educational_institution',
              text: this.$t('nameInstitution'),
              title: { global: this.$t('totalGlobal'), page: this.$t('totalOnPage') },
              sortable: false })
          }
          return ({ name: 'distribution-usual-report-qualification-educational-institution',
            params: { educational_institution: item.educational_institution.id },
            query: { ...this.$route.query } })
        default:
          return ({})
      }
    },
    checkLink () {
      switch (this.$route.params.typeDocument) {
        case 'sqc':
        case 'crewing_manager':
        case 'portal':
        case 'sc':
        case 'adv_training':
          return 'list'
        case 'dpd':
          if (this.$route.params.type) return 'dpd'
          return 'list'
        case 'eti':
          return 'course'
        case 'medical':
          return 'doctor'
        default:
          return 'crewingManager'
      }
    }
  }
}
</script>
